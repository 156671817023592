<template>
  <div class="container" id="comments">
    <el-row>
      <not-found-info :item="displayedComments" :title="'Değerlendirme'" :emptyName="'rates'" />
      <comments-card v-for="(item, index) in displayedComments" :key="index" :item="item" />
    </el-row>
    <el-row>
      <el-col :span="24" v-if="pages.length > 1" class="d-flex justify-content-center mb-5">
        <nav aria-label="Page navigation example">
          <ul class="pagination">
            <li class="page-item">
              <button class="page-link" type="button" aria-label="Previous" @click="page--">
                <span aria-hidden="true">&laquo;</span>
                <span class="sr-only">Previous</span>
              </button>
            </li>
            <li v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)" :key="index" @click="page = pageNumber" class="page-item" :class="{ active: pageNumber == page }">
              <button type="button" class="page-link text-white active">
                {{ pageNumber }}
              </button>
            </li>
            <li class="page-item">
              <a class="page-link" href="#" aria-label="Next" @click="page++">
                <span aria-hidden="true">&raquo;</span>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import CommentsCard from "../../components/commentCard/commentsCard.vue";
import NotFoundInfo from "../../components/notFound/notFoundInfo.vue";
import META_DATA from "../../constants/metaData";

export default {
  metaInfo() {
    return {
      title: META_DATA.COMMENTS.Title,
    };
  },
  components: {
    CommentsCard,
    NotFoundInfo,
  },
  data() {
    return {
      page: 1,
      perPage: 10,
      pages: [],
    };
  },
  async created() {
    if (this.$store.state.User.token) {
      await this.$store.dispatch("getMyRates");
    }
  },
  computed: {
    comments: {
      get() {
        return this.$store.getters["myRates"];
      },
    },
    displayedComments() {
      return this.paginate(this.comments);
    },
    loading() {
      return this.$store.getters["loading"];
    },
  },
  methods: {
    setPages() {
      let numberOfPages = Math.ceil(this.comments.length / this.perPage);
      this.pages = [];
      for (let i = 1; i <= numberOfPages; i++) {
        this.pages.push(i);
      }
    },
    paginate(comment) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return comment?.slice(from, to);
    },
  },
  watch: {
    comments() {
      this.setPages();
    },
    page() {
      if (this.page == 0) {
        this.page = this.pages.length;
      } else if (this.page > this.pages.length) {
        this.page = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#comments span {
  color: white;
  font-size: 14px;
}
</style>
